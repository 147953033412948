import { getPage } from "../data-interface/sanity";
import styles from "./404.module.scss";

export default function Page404() {
    return (
        <div className={styles.container}>
            <h1>404</h1>
        </div>
    );
}

export const getStaticProps = async (context) => {
    const { preview: isPreview = false } = context;
    const slug = "404";

    const pageData = await getPage(slug);

    if (!pageData) {
        return {
            notFound: true,
        };
    }

    return {
        props: {
            pageData,
            isPreview,
        },
        revalidate: parseInt(process.env.REVALIDATE),
    };
};
